// Variables file

$lightgreen: #ebf0e6;
$darkgreen: #809c66;
$darkergreen: #5f7e48;

$lightblue: #edeff1;
$mediumblue: #7a8396;
$blue: #304052;

$lightorange: #fdf0ee;
$mediumorange: #f49e90;
$orange: #f7755f;

$lightred: #cc0033;
$mediumred: #c03;
$red: #5d0017;

$darkgrey: #302e3a;
$grey: #c6c6c6;
$black: $blue;
$white: #fff;

// Image ratio
body {
  --image-aspect-ratio: 4/3;
}

// Mixins
@mixin green-button($bgcolor, $color) {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border: none;
  background-color: $bgcolor;
  color: $color;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

@mixin word-break() {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

:root {
  --custom-main-font: 'Source Sans 3';
  --custom-headers-font: 'Noto Sans';
}
