.header-wrapper {
  position: sticky;
  z-index: 14;
  top: 0;
  background-color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .header .logo-nav-wrapper {
    padding-top: 5px;
    padding-bottom: 20px;
  }
}

.navigation .item {
  color: $darkgrey;
}

.navigation .item.active::before,
.navigation .item:hover::before {
  bottom: -34px;
  display: block !important;
  border-bottom-color: $red;
}

.navigation ul.desktop-menu .submenu-wrapper {
  margin-top: 25px;
}

.navigation ul.desktop-menu > li > button,
.navigation ul.desktop-menu > li > a {
  margin-left: 50px;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

body.siteroot main {
  padding-top: 100px;
  background-image: url('images/rectangle.jpg');
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: 100%;
  color: #fff;

  h1.documentFirstHeading,
  h2,
  h3,
  h4,
  h5,
  h6,
  .documentDescription {
    color: #fff;
  }
}

body.siteroot #footer {
  margin-top: 0;
}

.content-area p > a,
.content-area li > a,
.content-area .breadcrumb a,
.breadcrumbs p > a,
.breadcrumbs li > a,
.breadcrumbs .breadcrumb a {
  color: $red !important;
}

#main .breadcrumbs .breadcrumb .section {
  background-color: $lightblue;
  font-weight: 400;
}

#main .breadcrumbs .breadcrumb .section.active {
  font-weight: 400;
}

#main .breadcrumbs .breadcrumb .divider::before {
  color: $red;
}

body.section-referenzen .block.listing.grid img {
  object-position: top left !important;
}

#footer .footer {
  max-width: 100%;
  background-color: #302e3a;
  color: $white;

  ul {
    flex-flow: wrap;

    li a {
      color: $white;
    }
  }
}
