.block.__button .ui.button:focus,
.block.__button .ui.button:hover,
.block.gridBlock .ui.button:focus,
.block.gridBlock .ui.button:hover {
  background-color: $red !important;
}

.block.__button .ui.button,
.block.gridBlock .ui.button {
  border-width: 0 !important;
  background-color: #cc0033 !important;
  color: #fff !important;
}

.block.gridBlock .ui.button {
  padding: 8px 20px;
  border-radius: unset;
  margin-top: 40px;
  background: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
