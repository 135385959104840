.blocks-group-wrapper.green_white,
[class*='block-editor-'].has--backgroundColor--green_white {
  background-color: $lightgreen;
}

.blocks-group-wrapper.blue_white,
[class*='block-editor-'].has--backgroundColor--blue_white {
  background-color: $lightblue;
}

.blocks-group-wrapper.orange_white,
[class*='block-editor-'].has--backgroundColor--orange_white {
  background-color: $lightorange;
}

.blocks-group-wrapper.red_white,
[class*='block-editor-'].has--backgroundColor--red_white {
  background-color: $red;
  color: #fff;

  .teaser-item {
    color: #fff;
  }

  h2,
  h3 {
    color: #fff;
  }
}

.blocks-group-wrapper.grey_white,
[class*='block-editor-'].has--backgroundColor--grey_white {
  background-color: $darkgrey;
  color: #fff;

  .teaser-item {
    color: #fff;
  }

  h2,
  h3 {
    color: #fff;
  }
}

.blocks-group-wrapper.white_black,
[class*='block-editor-'].has--backgroundColor--white_black {
  background-color: $white;
  color: $black;

  .teaser-item {
    color: #000;
  }

  h2,
  h3 {
    color: #000;
  }
}
