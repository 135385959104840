// Source Sans Pro font family
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  src: local('Source Sans 3');
  src: url('./fonts/SourceSans3-VariableFont_wght.ttf');
  src: url('./fonts/SourceSans3-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans 3';
  font-style: italic;
  src: local('Source Sans 3');
  src: url('./fonts/SourceSans3-Italic-VariableFont_wght.ttf');
  src: url('./fonts/SourceSans3-Italic-VariableFont_wght.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans');
  src: url('./fonts/NotoSans-Regular.ttf');
  src: url('./fonts/NotoSans-Regular.ttf') format('truetype');
}
