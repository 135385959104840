body {
  background-color: #fff;
}

.navigation .item.active::before,
.navigation .item:hover::before {
  border-bottom-color: $orange;
}

h1.documentFirstHeading,
h2,
h3,
h4,
h5 {
  color: $blue;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(
    --custom-headers-font,
    var(--custom-main-font, 'Inter', sans-serif)
  );
}

// Code-Block
.code-block-wrapper.edit textarea,
.code-block-wrapper.edit pre {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
}

#page-document .blocks-group-wrapper > .block.code {
  max-width: var(--default-container-width);
}

// Footer
.block.listing .footer {
  @include default-container-width();
  @include adjustMarginsToContainer($default-container-width);
  margin-top: 1rem;
  text-align: right;

  a {
    color: $blue;
    font-weight: 400;
  }

  a::before {
    content: '❯ ';
  }
}
