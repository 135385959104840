.color-picker-widget {
  .button.transparent,
  .button.transparent.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: linear-gradient(90deg, #fff 18px, $lightgreen 18px);
    background: radial-gradient(
      circle,
      darken($lightgreen, 10%) 40%,
      rgba(255, 255, 255, 1) 40%
    );
    //   #fff,
    //   #fff 10px,
    //   $lightgreen 10px,
    //   $lightgreen 20px
    // );
  }

  .button.white_black,
  .button.white_black.active,
  .button.outline-white {
    // background: linear-gradient(90deg, #fff 18px, $lightgreen 18px);
    background: radial-gradient(
      circle,
      darken($white, 10%) 40%,
      rgba(255, 255, 255, 1) 40%
    );
    //   #fff,
    //   #fff 10px,
    //   $white 10px,
    //   $white 20px
    // );
  }

  .button.green_white,
  .button.green_white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightgreen,
    //   $lightgreen 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 40%,
      darken($lightgreen, 10%) 40%
    );
  }

  .button.blue_white,
  .button.blue_white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightblue,
    //   $lightblue 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 40%,
      darken($lightblue, 10%) 40%
    );
  }

  .button.orange_white,
  .button.orange_white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightorange,
    //   $lightorange 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 40%,
      darken($lightorange, 10%) 40%
    );
  }

  .button.red_white,
  .button.red_white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $red,
    //   $red 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 40%,
      darken($red, 10%) 40%
    );
  }

  .button.grey_white,
  .button.grey_white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $darkgrey,
    //   $darkgrey 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 40%,
      darken($darkgrey, 10%) 40%
    );
  }
}
