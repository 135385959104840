#page-document .block.introduction {
  @include vertical-space-introduction();
}

.block.introduction {
  .block-container p,
  .block-container ul li,
  .block-container ol li,
  .slate-editor p,
  .slate-editor ul li,
  .slate-editor ol li {
    margin: 0;
    @include introduction();
    strong,
    strong span {
      font-weight: bold;
    }
    em,
    em span {
      font-style: italic;
    }
  }

  .block-container p:empty {
    margin-bottom: $block-vertical-space;
  }

  h2 {
    @include highlight-title();
    margin-bottom: $spacing-large;
    @media only screen and (max-width: $largest-mobile-screen) {
      margin-bottom: $spacing-medium;
    }
  }
}
